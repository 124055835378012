<template>
  <section class="RefImageNew-page">
    <div class="container py-5">
      <mega-card class="row">
        <div class="sm-down:col-12 md-up:col-6">
          <div class="card-body">
            <mega-drop-zone
              @change="renderFile"
              :type="['image/jpeg', 'image/png']"
            >
              <mega-image
                class="image bg-contain"
                :style="{ backgroundSize: 'contain' }"
                ratio="16x9"
                :src="preview"
              />
              <a
                v-if="is_exist"
                :href="existUrl"
                target="_blank"
                class="btn btn-danger w-100 mt-2"
                >{{ $t("ref_exist_btn") }} ID: {{ existId }}</a
              >
            </mega-drop-zone>
          </div>

          <div class="card-body">
            <mega-input :label="$t('new_model_name')" v-model="name" />

            <mega-textarea :label="$t('desc')" v-model="description" />
          </div>
        </div>

        <div class="sm-down:col-12 md-up:col-6">
          <div class="card-body">
            <mega-range
              class="bg-primary mb-0"
              :label="$t('ref_pic_width')"
              v-model="width"
              :min="10"
              :max="1000"
              :step="5"
            />
            <div class="d-flex justify-content-between text-muted">
              <small>10</small>
              <small>1000</small>
            </div>

            <mega-number
              :min="10"
              :max="1000"
              :step="5"
              v-model="width"
              :help="width + 'cm'"
            />

            <mega-switch
              class="w-100 mb-3 bg-success"
              :label="$t('ref_global_loc')"
              v-model="is_global"
            />

            <mega-switch
              class="w-100 mb-3 bg-success"
              :label="$t('ref_sticky')"
              v-model="is_sticky"
            />

            <map-widget
              ratio="16x9"
              get-location
              v-if="!is_global"
              :images="ref_images"
              @update="updatePosition"
              :is-global="is_global"
              :lat="position.lat || 0"
              :lng="position.lng || 0"
            />
          </div>
        </div>

        <div class="card-body col-12">
          <div class="navbar">
            <div class="navbar-group ml-auto">
              <mega-button class="btn-white btn-sm mr-2" @click="close">{{
                $t("close")
              }}</mega-button>
              <mega-button
                class="btn-success btn-sm"
                :disabled="!passed"
                :loading="img_loading"
                @click="submit"
                >{{ $t("model_save") }}</mega-button
              >
            </div>
          </div>
        </div>
      </mega-card>
    </div>
  </section>
</template>

<script>
import MapWidget from "./components/map";
import RoundTypes from "../../../data/round_types.json";

export default {
  name: "RefImageNewPage",
  props: ["modelId", "modId"],
  data() {
    return {
      position: {
        lat: 59.9598743,
        lng: 30.2965115
      },
      round_type: -1,
      keys: {},
      ref_images: [],
      modal: false,
      name: "",
      description: "",
      is_global: false,
      is_sticky: false,
      serverSide: true,
      preview: undefined,
      file: undefined,
      width: 100,
      percent: 50,
      is_exist: false,
      existId: null,
      existUrl: null,
      img_loading: false
    };
  },
  mounted() {
    this.$navbar.name = this.$t("ref_title");

    this.$api.v2
      .get("/model", { params: { idt_model: this.modelId } })
      .then(response => {
        let images = response.data["ref_images"] || [];

        this.round_type = response.data["idc_round_type"];

        this.ref_images = images.map(img => {
          let position = { lng: img.lng, lat: img.lat };

          return { position, is_global: img.is_global };
        });
      });
  },
  computed: {
    passed() {
      return this.width && this.percent && this.name;
    }
  },
  methods: {
    submit() {
      this.img_loading = true;
      this.is_exist = false;

      let formData = new FormData();
      formData.append("name", this.name);
      formData.append("description", this.description);
      formData.append("image", this.file);
      formData.append("server_side", this.serverSide);
      formData.append("idt_model_modif", this.modId);
      formData.append("is_global", this.is_global);
      formData.append("sticky", this.is_sticky);
      // formData.append("match_percent", this.percent);
      //formData.append("match_percent", 0);
      formData.append("width", this.width);
      formData.append("lng", this.position.lng);
      formData.append("lat", this.position.lat);

      this.$api.v2
        .post("/refImage", formData, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(response => response.data["ref_image"])
        .then(ref_image => {
          this.$alert.success(this.$t("ref_new_msg"));
        })
        .then(() => {
          RoundTypes.forEach(type => {
            if (this.round_type === type.value) {
              this.$router.push(`/${type.name}/${this.modelId}/${this.modId}`);
            }
          });
        })
        .catch(err => {
          const data = err.response.data;
          if (err.response.status === 400) {
            switch (data.code) {
              case 4:
                this.$alert.danger(this.$t("err_ref_small"));
                break;
              case 5:
                this.$alert.danger(this.$t("err_ref_quality"));
                break;
              case 6:
                this.getRouteName(data.details.matched_ref.idt_model).then(
                  name => {
                    this.existUrl = `/${name}/${data.details.matched_ref.idt_model}/${data.details.matched_ref.idt_model_modif}`;
                    this.existId = data.details.matched_ref.idt_ref_image;
                    this.is_exist = true;
                  }
                );

                this.$alert.danger(this.$t("err_ref_match"));
                break;
            }
          } else {
            this.$alert.danger(this.$t("err_server"));
          }
        })
        .finally(() => {
          this.img_loading = false;
        });
    },

    close() {
      RoundTypes.forEach(type => {
        if (this.round_type === type.value) {
          this.$router.push(`/${type.name}/${this.modelId}/${this.modId}`);
        }
      });
    },

    renderFile(file) {
      this.validateImageSize(file)
        .then(() => {
          this.file = file;

          let reader = new FileReader();
          reader.readAsDataURL(this.file);
          reader.onloadend = () => {
            this.preview = reader.result;
          };
        })
        .catch(() => {
          this.$alert.danger(this.$t("err_ref_size"));
        });
    },

    updatePosition(location) {
      this.position.lat = location.lat;
      this.position.lng = location.lng;
    },

    validateImageSize(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onloadend = () => {
          const img = new Image();
          img.onload = () => {
            if (img.width < 480 && img.height < 480) {
              reject();
            }

            resolve();
          };

          img.src = reader.result;
        };

        reader.readAsDataURL(file);
      });
    },

    getRouteName(modelId) {
      return this.$api.v2
        .get("/model", { params: { idt_model: modelId } })
        .then(({ data }) => data.idc_round_type)
        .then(type => RoundTypes.filter(item => item.value == type)[0].name)
        .catch(err => {
          console.log(err);
          this.$alert.danger(this.$t("err_server"));
        });
    }
  },
  components: {
    MapWidget
  }
};
</script>
