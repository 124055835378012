var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "RefImageNew-page" }, [
    _c(
      "div",
      { staticClass: "container py-5" },
      [
        _c("mega-card", { staticClass: "row" }, [
          _c("div", { staticClass: "sm-down:col-12 md-up:col-6" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c(
                  "mega-drop-zone",
                  {
                    attrs: { type: ["image/jpeg", "image/png"] },
                    on: { change: _vm.renderFile }
                  },
                  [
                    _c("mega-image", {
                      staticClass: "image bg-contain",
                      style: { backgroundSize: "contain" },
                      attrs: { ratio: "16x9", src: _vm.preview }
                    }),
                    _vm._v(" "),
                    _vm.is_exist
                      ? _c(
                          "a",
                          {
                            staticClass: "btn btn-danger w-100 mt-2",
                            attrs: { href: _vm.existUrl, target: "_blank" }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("ref_exist_btn")) +
                                " ID: " +
                                _vm._s(_vm.existId)
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("mega-input", {
                  attrs: { label: _vm.$t("new_model_name") },
                  model: {
                    value: _vm.name,
                    callback: function($$v) {
                      _vm.name = $$v
                    },
                    expression: "name"
                  }
                }),
                _vm._v(" "),
                _c("mega-textarea", {
                  attrs: { label: _vm.$t("desc") },
                  model: {
                    value: _vm.description,
                    callback: function($$v) {
                      _vm.description = $$v
                    },
                    expression: "description"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "sm-down:col-12 md-up:col-6" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("mega-range", {
                  staticClass: "bg-primary mb-0",
                  attrs: {
                    label: _vm.$t("ref_pic_width"),
                    min: 10,
                    max: 1000,
                    step: 5
                  },
                  model: {
                    value: _vm.width,
                    callback: function($$v) {
                      _vm.width = $$v
                    },
                    expression: "width"
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between text-muted" },
                  [
                    _c("small", [_vm._v("10")]),
                    _vm._v(" "),
                    _c("small", [_vm._v("1000")])
                  ]
                ),
                _vm._v(" "),
                _c("mega-number", {
                  attrs: {
                    min: 10,
                    max: 1000,
                    step: 5,
                    help: _vm.width + "cm"
                  },
                  model: {
                    value: _vm.width,
                    callback: function($$v) {
                      _vm.width = $$v
                    },
                    expression: "width"
                  }
                }),
                _vm._v(" "),
                _c("mega-switch", {
                  staticClass: "w-100 mb-3 bg-success",
                  attrs: { label: _vm.$t("ref_global_loc") },
                  model: {
                    value: _vm.is_global,
                    callback: function($$v) {
                      _vm.is_global = $$v
                    },
                    expression: "is_global"
                  }
                }),
                _vm._v(" "),
                _c("mega-switch", {
                  staticClass: "w-100 mb-3 bg-success",
                  attrs: { label: _vm.$t("ref_sticky") },
                  model: {
                    value: _vm.is_sticky,
                    callback: function($$v) {
                      _vm.is_sticky = $$v
                    },
                    expression: "is_sticky"
                  }
                }),
                _vm._v(" "),
                !_vm.is_global
                  ? _c("map-widget", {
                      attrs: {
                        ratio: "16x9",
                        "get-location": "",
                        images: _vm.ref_images,
                        "is-global": _vm.is_global,
                        lat: _vm.position.lat || 0,
                        lng: _vm.position.lng || 0
                      },
                      on: { update: _vm.updatePosition }
                    })
                  : _vm._e()
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body col-12" }, [
            _c("div", { staticClass: "navbar" }, [
              _c(
                "div",
                { staticClass: "navbar-group ml-auto" },
                [
                  _c(
                    "mega-button",
                    {
                      staticClass: "btn-white btn-sm mr-2",
                      on: { click: _vm.close }
                    },
                    [_vm._v(_vm._s(_vm.$t("close")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "mega-button",
                    {
                      staticClass: "btn-success btn-sm",
                      attrs: {
                        disabled: !_vm.passed,
                        loading: _vm.img_loading
                      },
                      on: { click: _vm.submit }
                    },
                    [_vm._v(_vm._s(_vm.$t("model_save")))]
                  )
                ],
                1
              )
            ])
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }